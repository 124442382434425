import elliptical from '../references/elliptical';
import container from '../dependencies/container';
import utils from '../references/utils';

elliptical.binding('detail-location-search', function (node) {
    console.log('binding ready');
    var $node = $(node);
    var template=container.getType('template');
    var TEMPLATE_NAME='shared.detail-location-results';
    var Notify= container.getType('Notify');
    var DomEvent = container.getType('DomEvent');
    var dom = new DomEvent(node, this);
    var txtInputZip=dom.find('#txtZipCode');
    var resultsContainer=dom.find('#locationResults');
    var click=this.click;
    
    dom.event($node,click,'#btnZipCode',onSubmit);

    function onSubmit(event){
        var zipCode=txtInputZip.val();
        if(zipCode && zipCode !==''){
            getLocations(zipCode);
        }
    }

    async function getLocations(id){
        var Locations=container.getType('/PromotionLocation/' + id);
        try{
            let model=await Locations.getAsync();
            if(model.stores && model.stores.length > 0){
                model=formatModel(model);
                renderTemplate(model);
            }else{
                resultsContainer.addClass('hide');
                Notify.show('No locations available for the submitted zip code');
            }  
        }catch(ex){

        }
    }

    function renderTemplate(model){
        template.render(TEMPLATE_NAME,model,(err,out)=>{
            if(!err){
                resultsContainer.removeClass('hide');
                resultsContainer.html(out);
            }else{
                Notify.show('Error generating locations');
            }
        }); 
    }

    function formatModel(model){
        model.stores=formatStores(model.stores);
        model.locations=JSON.stringify(model.locations);
        return model;
    }

    function formatStores(stores){
        for(var i=0;i<stores.length;i++){
            stores[i].mapLink=toMapLink(stores[i]);
            stores[i].storeLink=toStoreLink(stores[i].url);
        }
        return stores;
    }

    function toMapLink(source){
        return "http://maps.google.com/maps?saddr=&daddr=" + source.street + "," + source.city + "," + source.state;
    }

    function toStoreLink(source){
        if(source.indexOf("http://")>-1 || source.indexOf("https://")>-1){
            return source;
        }
        else{
            return "http://" + source;
        }
    }

    this.dispose = ()=> {
        dom.unbind();
    };

});


   