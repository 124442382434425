import elliptical from '../references/elliptical';
import container from '../dependencies/container';


elliptical.binding('component-loaded', function (node) {
    var DomEvent = container.getType('DomEvent');
    var dom = new DomEvent(node, this);
    console.log('binding fired');
    dom.event(document,'content-component-ready',onReady);

    function onReady(){
        node.style.display='none';
    }

    onReady();

    this.dispose = ()=> {
        dom.unbind();
    };

});


   