import elliptical from '../references/elliptical';
import container from '../dependencies/container';
import keys from '../references/keys';

var apiKey=keys.GOOGLE_API_KEY;
var maxResults=25;
var youtubeRootUrl='https://www.youtube.com/watch?v=';

class YoutubeService{
   static get(id,callback){
       let url= "https://www.googleapis.com/youtube/v3/videos?part=snippet%2Cstatistics&key=" + apiKey + "&id=" + id;
       $.get(url,function(data){
           let obj=data.items[0];
           let id=obj.id;
           let description=obj.snippet.description;
           description=description.replace(/(?:\r\n|\r|\n)/g, '<br>');
           let img;
           if(obj.snippet.thumbnails.maxres) img= obj.snippet.thumbnails.maxres.url;
           else img=obj.snippet.thumbnails.standard.url;
           var result= {
               id:id,
               thumb:img,
               title:obj.snippet.title,
               url:youtubeRootUrl + id,
               channelId:obj.snippet.channelId,
               mobileThumb:obj.snippet.thumbnails.medium.url,
               description:description,
               dislikeCount:obj.statistics.dislikeCount,
               likeCount:obj.statistics.likeCount,
               viewCount:obj.statistics.viewCount
           };
           callback(null,result);
        })
       .fail(function() {
           callback({statusCode:500,message:'Error retrieving video'},null);       
       });
   }

   static getPlaylist(id,callback){
        let url = "https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=" + maxResults.toString() + "&playlistId=" + id + "&key=" + apiKey;
        $.get(url,function(data){
            var items=data.items.map(function(x){
                let id=x.snippet.resourceId.videoId;
                return {
                    id:id,
                    playlistId: x.snippet.playlistId,
                    thumb: x.snippet.thumbnails.medium.url,
                    title: x.snippet.title,
                    url: youtubeRootUrl + id,
                };
            });
            callback(null,items);
         })
        .fail(function() {
            callback({statusCode:500,message:'Error retrieving playlist videos'},null);       
        });
   }

   static getAsync(id){
       var self=this;
       return new Promise(function(resolve,reject){
            self.get(id,function(err,data){
                if(err) reject(err);
                else resolve(data);
            });
       });
   }

   static getPlaylistAsync(id){
       var self=this;
       return new Promise(function(resolve,reject){
            self.getPlaylist(id,function(err,data){
                if(err) reject(err);
                else resolve(data);
            });
       });
   }

}

container.registerType('Youtube', YoutubeService);


  