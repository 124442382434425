import elliptical from '../references/elliptical';
import container from '../dependencies/container';
import utils from '../references/utils';

elliptical.binding('request-catalog', function (node) {
    var random=utils.random;
    var id=random.id(6);
    var $document=$(document);
    var TEMPLATE_NAME='shared.request-catalog';
    var ACTION_ID='catalog-request-' + id;
    var dialog=document.querySelector('#pageDialog');
    var template=container.getType('template');
    var DomEvent = container.getType('DomEvent');
    var Notify= container.getType('Notify');
    var dom = new DomEvent(node, this);
    dom.event(this.click,onClick);
    dom.event($document,'md-dialog-action', onSubmit);
    dom.event($document,'md-dialog-cancel', onCancel);
    dom.event($document,'silicon-form-success', onSuccess);

    function onClick(event){
       dialog.setAction('Submit',ACTION_ID);
       dialog.hideActions();
       template.render(TEMPLATE_NAME,{},function(err,out){
          dialog.setContent(out);
          dialog.open(true,600,700);
          var form=dialog.querySelector('silicon-form');
          form.formId=ACTION_ID;
       }); 
    }

    function onSubmit(event){
        if(event.detail.actionId!==ACTION_ID) return;
        var form=dialog.querySelector('silicon-form');
        form.submit()
    }

    function onCancel(event){
         if(event.detail.actionId!==ACTION_ID) return;
         dialog.reset();
    }

    function onSuccess(event){
        if(event.detail.id!==ACTION_ID) return;
        dialog.close();
        dialog.reset();
    }

    this.dispose = ()=> {
        dom.unbind();
    };

});


   