import elliptical from '../references/elliptical';
import container from '../dependencies/container';


elliptical.binding('location-menu', function (node) {
    var HREF='/Dealer';
    var $node=$(node);
    var MEGA_DROPDOWN=$node.parent('ui-mega-dropdown');
    var DomEvent = container.getType('DomEvent');
    var Notify=container.getType('Notify');
    var dom = new DomEvent(node, this);
    dom.event(this.click,'button', onClick);
    dom.event('change','select', onSelect);
    dom.event('mouseover', onHover);

    function onClick(event){
      var id=event.target.getAttribute('id');
      if(id==='btnMenuZipRange') locationsByZipCode();
      else if(id==='btnMenuStateSelect') locationsByState();
      else if(id==='btnMenuCountrySelect') locationsByCountry();
    }

    function locationsByZipCode(){
       var type='zipcode';
       var zipCode=$node.find('[data-id="menuZipCode"]').val();
       var range=$node.find('[data-id="menuSelectRange"]')[0];
       var miles=range.value;
       if(zipCode && zipCode !=='' && miles !=='select'){
           var href=HREF + '?searchType=' + type + '&zipcode=' + zipCode + '&miles=' + miles;
           location.href=href;
       }else{
           Notify.show('Zip code and selected range required');
       }
    }

    function locationsByState(){
        var type='state';
        var stateSelect=$node.find('[data-id="menuSelectState"]')[0];
        var state=stateSelect.value;
        if(state !=='select'){
           var href=HREF + '?searchType=' + type + '&state=' + state;
           location.href=href;
        }else{
             Notify.show('State selection required');
        }
    }

    function locationsByCountry(){
        var type='country';
        var countrySelect=$node.find('[data-id="menuSelectCountry"]')[0];
        var country=countrySelect.value;
        if(country !=='select'){
           var href=HREF + '?searchType=' + type + '&country=' + country;
           location.href=href;
        }else{
             Notify.show('Country selection required');
        }
    }

    function onSelect(event){
        MEGA_DROPDOWN.addClass('show');
    }

     function onHover(event){
        MEGA_DROPDOWN.removeClass('show');
    }

    this.dispose = ()=> {
        dom.unbind();
    };

});


   