import elliptical from "../references/elliptical";
import container from '../dependencies/container';
import {progress} from '../modules/ui';
import keys from '../references/keys';

var PAGE_SIZE=keys.PAGE_SIZE;
var Location=container.getType('Location');
var ProductHoliday=container.getType('/Promotion/ShopHoliday');

var Y_VALUE=200;
var Y_OFFSET=800;

export default class Controller extends elliptical.Controller {
    async ShopHoliday(req, res, next) {
        let queryType='gallery'
        let queryId=null;
        let queryString=getQueryString(queryType,queryId);
        let pageSize=PAGE_SIZE;
        let page = 1;
        if (req.query.page) page = parseInt(req.query.page);
        let baseUrl = '/Promotion/ShopHoliday';
        let rawUrl = req.url;
        progress.start();
        let product=new ProductHoliday();
        try {
            let result = await product.paginate({baseUrl, rawUrl, page, pageSize})
                .filter(req.query)
                .getAsync();
            let items = result.data;
            let pagination = result.pagination;
            let count = pagination.count;
            let context = {items, pagination, count,queryString};
            res.render(context,()=>res.animateScrollTop({y:500,delay:0}));
        } catch (err) {
            next(err);
        }
    }
}
    
function getQueryString(queryType,queryId){
    var search=Location.search;
    if(search.indexOf('?page') > -1) search= '';
    else if(search.indexOf('&page')>-1){
      var index=search.indexOf('&page');
      search=search.substr(0,index);
    }
    if(search==='') search+='?$queryType=' + encodeURIComponent(queryType);
    else if(search.indexOf('$queryType')<0) search+='&$queryType=' + encodeURIComponent(queryType);
    if(queryId){
        if(search.indexOf('$queryId')<0) search+='&$queryId=' + encodeURIComponent(queryId);
    }
    return search;
}    

function toCategoryIdString(source){
 source=source.replace(/~/g,'&');
 source=source.replace(/^/g,/\//);
 source=encodeURIComponent(source);
 return source;
}

function toSpaceDelimitedString(source){
 return source.replace(/-/g, ' ');
}
