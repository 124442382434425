import elliptical from '../references/elliptical';
import container from '../dependencies/container';


elliptical.binding('carousel-index', function (node) {
    var carousel=null;
    var Boris=container.getType('Boris');
    var DomEvent = container.getType('DomEvent');
    var dom = new DomEvent(node, this);
    var nextIcon=$('#nextIcon');
    var prevIcon=$('#prevIcon');
    dom.event(nextIcon,'click',onNext);
    dom.event(prevIcon,'click',onPrev);

    function getCarousel(){
       return (carousel) ? carousel : document.querySelector('#main-carousel');
    }
    
    function onNext(event){
       var mainCarousel=getCarousel();
       mainCarousel.slide('next');
    }

    function onPrev(event){
       var mainCarousel=getCarousel();
       mainCarousel.slide('prev');
    }

    
    this.dispose = ()=> {
        dom.unbind();
    };

});


   