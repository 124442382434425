import elliptical from '../references/elliptical';
import container from '../dependencies/container';


elliptical.binding('fabric', function (node) {
    var $node = $(node);
    var gallery=document.querySelector('#fabricGallery');
    var DomEvent = container.getType('DomEvent');
    var dom = new DomEvent(node, this);
    var click=this.click;
    dom.event($node,'click','.finish-item',onClick);

    function onClick(event){
        var index=event.target.dataset.index;
        gallery.show(index);
    }

    this.dispose = ()=> {
        dom.unbind();
    };

});


   