import elliptical from '../references/elliptical';
import container from '../dependencies/container';


elliptical.binding('main-fade-in', function (node) {
    var screenWidth=screen.width;
    var delay=(screenWidth >1024) ? 1000 : 2000;
    
    //setTimeout(function(){
      //node.style.opacity=1;
     //},delay);

});


   