import elliptical from '../references/elliptical';
import container from '../dependencies/container';


elliptical.binding('subscribe', function (node) {
    var DomEvent = container.getType('DomEvent');
    var dom = new DomEvent(node, this);
    var Subscribe=container.getType('Subscribe');
    var Notify=container.getType('Notify');
    var click=this.click;
    dom.event('click','button',onClick);

    function onClick(event){
        var input=dom.find('[data-id="email"]');
        var email=input.val();
        let entity={email};
        sendEmail(entity);
    }

    async function sendEmail(entity){
        try{
          let result = await Subscribe.postAsync(entity);
          Notify.show('Your email address has been successfully subscribed'); 
        }catch(err){
          Notify.show('Error: ' + err.message);
        }
    }
    
    this.dispose = ()=> {
        dom.unbind();
    };

});


   