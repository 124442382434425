import elliptical from "../references/elliptical";
import container from '../dependencies/container';
import {progress} from '../modules/ui';
import keys from '../references/keys';

var PAGE_SIZE=keys.PAGE_SIZE;
var Location=container.getType('Location');
var ProductGallery=container.getType('/Product/Gallery');
var ProductCategory=container.getType('/Product/Category');
var ProductPortfolio=container.getType('/Product/Portfolio');
var ProductLook=container.getType('/Product/Look');
var ProductComingSoon=container.getType('/Product/ComingSoon');
var ProductNew=container.getType('/Product/New');
var ProductSearch=container.getType('/Product/Search');


var Y_VALUE=200;
var Y_OFFSET=800;

export default class Controller extends elliptical.Controller {
    async Gallery(req, res, next) {
        let queryType='gallery'
        let queryId=null;
        let queryString=getQueryString(queryType,queryId);
        let pageSize=PAGE_SIZE;
        let page = 1;
        if (req.query.page) page = parseInt(req.query.page);
        let baseUrl = '/Product/Gallery/1';
        let rawUrl = req.url;
        progress.start();
        let product=new ProductGallery();
        try {
            let result = await product.paginate({baseUrl, rawUrl, page, pageSize})
                .filter(req.query)
                .getAsync();
            let items = result.data;
            let pagination = result.pagination;
            let count = pagination.count;
            let context = {items, pagination, count,queryString};
            console.log('gallery');
            res.render(context,()=>res.animateScrollTop());
        } catch (err) {
            next(err);
        }
    }

    async Category(req, res, next) {
        let pageSize=PAGE_SIZE;
        let id=req.params.id;
        let queryType='category'
        let queryId=id;
        let queryString=getQueryString(queryType,queryId);
        let page = 1;
        if (req.query.page) page = parseInt(req.query.page);
        let baseUrl = '/Product/Category/' + id;
        let rawUrl = req.url;
        progress.start();
        let product=new ProductCategory();
        try {
            let result = await product.paginate({baseUrl, rawUrl, page, pageSize})
                .filter(req.query)
                .getAsync({id});
            let items = result.data;
            let pagination = result.pagination;
            let count = pagination.count;
            let context = {items, pagination, count,queryString};
            res.render(context,()=>res.animateScrollTop());
        } catch (err) {
            next(err);
        }
    }

    async Portfolio(req, res, next) {
        let pageSize=PAGE_SIZE;
        let id=req.params.id;
        let queryType='portfolio'
        let queryId=null;
        let queryString=getQueryString(queryType,queryId);
        let page = 1;
        if (req.query.page) page = parseInt(req.query.page);
        let baseUrl = '/Product/Portfolio/' + id;
        let rawUrl = req.url;
        progress.start();
        let product=new ProductPortfolio();
        try {
            let result = await product.paginate({baseUrl, rawUrl, page, pageSize})
                .filter(req.query)
                .getAsync({id});
            let items = result.data;
            let pagination = result.pagination;
            let count = pagination.count;
            let context = {items, pagination, count,queryString};
            res.render(context,()=>res.animateScrollTop({y:Y_VALUE,yOffset:Y_OFFSET}));
        } catch (err) {
            next(err);
        }
    }

    async Look(req, res, next) {
        let pageSize=PAGE_SIZE;
        let id=req.params.id;
        let queryType='look'
        let queryId=id;
        let queryString=getQueryString(queryType,queryId);
        let page = 1;
        if (req.query.page) page = parseInt(req.query.page);
        let baseUrl = '/Product/Look/' + id;
        let rawUrl = req.url;
        progress.start();
        let product=new ProductLook();
        try {
            let result = await product.paginate({baseUrl, rawUrl, page, pageSize})
                .filter(req.query)
                .getAsync({id});
            let items = result.data;
            let pagination = result.pagination;
            let count = pagination.count;
            let context = {items, pagination, count,queryString};
            res.render(context,()=>res.animateScrollTop({y:Y_VALUE,yOffset:Y_OFFSET}));
        } catch (err) {
            next(err);
        }
    }

    async ComingSoon(req, res, next) {
        let pageSize=PAGE_SIZE;
        let queryType='soon'
        let queryId=null;
        let queryString=getQueryString(queryType,queryId);
        let page = 1;
        if (req.query.page) page = parseInt(req.query.page);
        let baseUrl = '/Product/ComingSoon/1';
        let rawUrl = req.url;
        progress.start();
        let product=new ProductComingSoon();
        try {
            let result = await product.paginate({baseUrl, rawUrl, page, pageSize})
                .filter(req.query)
                .getAsync();
            let items = result.data;
            let pagination = result.pagination;
            let count = pagination.count;
            let context = {items, pagination, count,queryString};
            res.render(context,()=>res.animateScrollTop());
        } catch (err) {
            next(err);
        }
    }

    async New(req, res, next) {
        let pageSize=PAGE_SIZE;
        let queryType='new'
        let queryId=null;
        let queryString=getQueryString(queryType,queryId);
        let page = 1;
        if (req.query.page) page = parseInt(req.query.page);
        let baseUrl = '/Product/New/1';
        let rawUrl = req.url;
        progress.start();
        let product=new ProductNew();
        try {
            let result = await product.paginate({baseUrl, rawUrl, page, pageSize})
                .filter(req.query)
                .getAsync();
            let items = result.data;
            let pagination = result.pagination;
            let count = pagination.count;
            let context = {items, pagination, count,queryString};
            res.render(context,()=>res.animateScrollTop());
        } catch (err) {
            next(err);
        }
    }

    async Search(req, res, next) {
        let pageSize=PAGE_SIZE;
        let id=req.query.$s;
        let queryType='search'
        let queryId=id;
        let queryString=getQueryString(queryType,queryId);
        var page = 1;
        if (req.query.page) page = parseInt(req.query.page);
        let baseUrl = '/Product/Search/1';
        let rawUrl = req.url;
        progress.start();
        let product=new ProductSearch();
        try {
            let result = await product.paginate({baseUrl, rawUrl, page, pageSize})
                .filter(req.query)
                .getAsync({id});
            let items = result.data;
            let pagination = result.pagination;
            let count = pagination.count;
            let context = {items, pagination, count,queryString};
            res.render(context,()=>res.animateScrollTop());
        } catch (err) {
            next(err);
        }
    }
}
    
function getQueryString(queryType,queryId){
    var search=Location.search;
    if(search.indexOf('?page') > -1) search= '';
    else if(search.indexOf('&page')>-1){
      var index=search.indexOf('&page');
      search=search.substr(0,index);
    }
    if(search==='') search+='?$queryType=' + encodeURIComponent(queryType);
    else if(search.indexOf('$queryType')<0) search+='&$queryType=' + encodeURIComponent(queryType);
    if(queryId){
        if(search.indexOf('$queryId')<0) search+='&$queryId=' + encodeURIComponent(queryId);
    }
    return search;
}    

function toCategoryIdString(source){
 source=source.replace(/~/g,'&');
 source=source.replace(/^/g,/\//);
 source=encodeURIComponent(source);
 return source;
}

function toSpaceDelimitedString(source){
 return source.replace(/-/g, ' ');
}




