import elliptical from '../references/elliptical';
import container from '../dependencies/container';


elliptical.binding('youtube', function (node) {
    var PLAYLIST_HEADER=null;
    var YOUTUBE_CAROUSEL=null;
    var VIEW_COUNT=null;
    var LIKE_COUNT=null;
    var DISLIKE_COUNT=null;
    var YOUTUBE_URL=null;
    var YOUTUBE_DESCRIPTION=null;
    var YOUTUBE_TITLE=null;
    var YOUTUBE_VIDEO=null;
    var GOOGLE_YOUTUBE=null;
    var YOUTUBE_PLAY=null;
    var VIDEOLIST_CAROUSEL=null;
    
    var stateChangeCount=0;
    var playlistItemClickedCount=0;
    var YouTube=container.getType('Youtube');
    var Notify=container.getType('Notify');
    var DomEvent = container.getType('DomEvent');
    var dom = new DomEvent(node, this);
    var click=this.click;
    var press=getPressEvent();
    console.log(press);
    dom.event(press,'[data-playlistid]',onPlaylistItemClick);
    dom.event(press,'[data-videoid]',onVideolistItemClick);
    dom.event(click,'#youtubeUrl', onView);
    dom.event(document,'google-youtube-state-change',onChange);
    
    function init(){
       PLAYLIST_HEADER=node.querySelector('#playlistHeader');
       YOUTUBE_CAROUSEL=node.querySelector('#youtube-carousel');
       YOUTUBE_DESCRIPTION=node.querySelector('#videoDescription');
       YOUTUBE_TITLE=node.querySelector('#videoTitle');
       YOUTUBE_URL=node.querySelector('#youtubeUrl');
       VIEW_COUNT=node.querySelector('#viewCount');
       LIKE_COUNT=node.querySelector('#likeCount');
       DISLIKE_COUNT=node.querySelector('#dislikeCount');
       VIDEOLIST_CAROUSEL=node.querySelector('#videolist-carousel');
       GOOGLE_YOUTUBE=node.querySelector('#google-youtube');
       YOUTUBE_PLAY=node.querySelector('#youtubePlay');
       setPlayer();
    }

    function onPlaylistItemClick(event){
        var target=event.currentTarget;
        var id=target.dataset.playlistid;
        var title=target.dataset.title;
        getVideolist(id,title);
    }

    function onVideolistItemClick(event){
        var target=event.currentTarget;
        var id=target.dataset.videoid;
        getVideo(id);
    }

    function onView(event){
        var target=event.currentTarget;
        var url=target.dataset.url;
        window.open(url);
    }

    function onChange(event){
        if(stateChangeCount===0){
            stateChangeCount=1;
            playlistItemClickedCount=1;
            YOUTUBE_PLAY.remove();
        }
    }

    async function getVideolist(id,title){
        try{
           var items=await YouTube.getPlaylistAsync(id);
           bindVideolist(items,title);
        }catch(err){
            console.log(err);
            Notify.show('Error retrieving playlist videos');
        }
    }
    
    async function getVideo(id){
        try{
            var entity=await YouTube.getAsync(id);
            bindVideo(entity);
        }catch(err){
            console.log(err);
            Notify.show('Error retrieving video');
        }
    }

    function bindVideolist(data,title){
        PLAYLIST_HEADER.innerHTML=title;
        YOUTUBE_CAROUSEL.setItems(data);
        VIDEOLIST_CAROUSEL.slideFirst();
    }

    function bindVideo(data){
        VIEW_COUNT.innerHTML=data.viewCount;
        LIKE_COUNT.innerHTML=data.likeCount;
        DISLIKE_COUNT.innerHTML=data.dislikeCount;
        YOUTUBE_URL.dataset.url=data.url;
        YOUTUBE_DESCRIPTION.innerHTML=data.description;
        YOUTUBE_TITLE.innerHTML=data.title;
        if(playlistItemClickedCount===0){
            GOOGLE_YOUTUBE.thumbnail='';
            YOUTUBE_PLAY.remove();
            playlistItemClickedCount=1;
            stateChangeCount=1;
        }
        GOOGLE_YOUTUBE.videoId=data.id;
        if(isTouch()) scrollTop();
        else animateScrollTop();
    }
 
    function setPlayer(){
        var screenWidth=window.innerWidth || document.body.clientWidth;
        if(screenWidth>=1280) {
            GOOGLE_YOUTUBE.classList.remove('hidden');
            YOUTUBE_PLAY.classList.remove('hidden');
            return;
        }
        var aspectRation=720/1280;
        var elementWidth=GOOGLE_YOUTUBE.offsetWidth;
        var height=parseInt(aspectRation*elementWidth);
        GOOGLE_YOUTUBE.width=elementWidth + 'px';
        GOOGLE_YOUTUBE.height=height + 'px';
        GOOGLE_YOUTUBE.classList.remove('hidden');
        YOUTUBE_PLAY.classList.remove('hidden');
    }

    function isTouch(){
        return 'ontouchend' in document
    }

    function animateScrollTop(){
        setTimeout(function(){
           $('html,body').animate({
             scrollTop: 250
           }, 500);
        },250);
    }

    function scrollTop(){
       setTimeout(function(){
         window.scrollTo(0,20);
       },250);
    }

    function getPressEvent(){
        if(isTouch()) return 'longpress';
        else return 'click';
    }
    
    //init
    init();

    this.dispose = ()=> {
        dom.unbind();
    };

});


   