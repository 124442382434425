import elliptical from '../references/elliptical';
import container from '../dependencies/container';

var ROOT_URL='http://media.r3vstack.com/assets/caracole/finishes/';

class FinishService{
   get(params,callback){
       var arr=[];
       var data=params.data;
       data.forEach(function(obj){
            var items=obj.items;
            for(var i=0;i<items.length;i++){
               var item={
                   src:ROOT_URL + items[0] + '.jpg?width=500&height=500',
                   subHtml:'<span>' + obj.name + '</span>'
               };
               arr.push(item);
            }
       });
       if(callback) callback(null,arr);
   }
}

container.registerType('FinishService', new FinishService());


  