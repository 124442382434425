import elliptical from '../references/elliptical';
import container from '../dependencies/container';

var USER_ID='550486405';
var CLIENT_ID='9ad74d08515b46a3aa998b434b42d88a';
var ACCESS_TOKEN='550486405.9ad74d0.e3e2ad72947e4a4a99d0e27fa8126c58';
var url='https://api.instagram.com/v1/users/550486405/media/recent?access_token=550486405.9ad74d0.e3e2ad72947e4a4a99d0e27fa8126c58';
class InstagramService {
   
   static get(callback){
       var feed = new Instafeed({
           get: 'user',
           userId: USER_ID,
           tagName: 'awesome',
           clientId: CLIENT_ID,
           accessToken:ACCESS_TOKEN,
           success:function(data){
               console.log(data);
               callback(null,data);
           },
           error:function(err){
               callback(err,null);
           }
       });

       feed.run();
   }

   static async getAsync(){
       var self=this;
       return new Promise(function(resolve,reject){
           self.get(function(err,data){
               if(err) reject(err);
               else resolve(data);
           });
       });
   }
}

container.registerType('Instagram', InstagramService);


  