import elliptical from '../references/elliptical';
import container from '../dependencies/container';


elliptical.binding('search', function (node) {
    var BASE_URL='/Product/Search/1';
    var DomEvent = container.getType('DomEvent');
    var dom = new DomEvent(node, this);

    dom.event('ui-input-addon-press',onSubmit);

    function onSubmit(event){
        let data=event.detail;
        var value=data.value;
        if(value==='') return;
        location.href=BASE_URL + '?$s=' + encodeURIComponent(value);
    }
    
    this.dispose = ()=> {
        dom.unbind();
    };

});


   