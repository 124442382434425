import elliptical from "../references/elliptical";


export default class PageController extends elliptical.PageController {
    
}
    






