import elliptical from '../references/elliptical';
import container from '../dependencies/container';


elliptical.binding('location-results', function (node) {
    var HREF='/Dealer';
    var $node=$(node);
    var DomEvent = container.getType('DomEvent');
    var Notify=container.getType('Notify');
    var dom = new DomEvent(node, this);
    dom.event(this.click,'[data-toggle]',onToggleClick);
    dom.event(this.click,'button', onButtonClick);

    setMilesSelectValue();

    function onToggleClick(event){
        var target=event.target;
        var id=target.dataset.toggle;
        dom.find('[data-selection-box]').addClass('hide');
        dom.find('[data-selection-box="' + id + '"]').removeClass('hide');
    }
   
    function onButtonClick(event){
      var id=event.target.getAttribute('id');
      if(id==='btnZipRange') locationsByZipCode();
      else if(id==='btnStateSelect') locationsByState();
      else if(id==='btnCountrySelect') locationsByCountry();
    }

    function locationsByZipCode(){
       var type='zipcode';
       var zipCode=$node.find("#zipCode").val();
       var range=$node.find('[data-id="selectRange"]')[0];
       var miles=range.value;
       if(zipCode && zipCode !=='' && miles !=='select'){
           var href=HREF + '?searchType=' + type + '&zipcode=' + zipCode + '&miles=' + miles;
           location.href=href;
       }else{
           Notify.show('Zip code and selected range required');
       }
    }

    function locationsByState(){
        var type='state';
        var stateSelect=$node.find('[data-id="selectState"]')[0];
        var state=stateSelect.value;
        if(state !=='select'){
           var href=HREF + '?searchType=' + type + '&state=' + state;
           location.href=href;
        }else{
             Notify.show('State selection required');
        }
    }

    function locationsByCountry(){
        var type='country';
        var countrySelect=$node.find('[data-id="selectCountry"]')[0];
        var country=countrySelect.value;
        if(country !=='select'){
           var href=HREF + '?searchType=' + type + '&country=' + country;
           location.href=href;
        }else{
             Notify.show('Country selection required');
        }
    }

    function setMilesSelectValue(){
       var uiSelect=dom.find('ui-select');
       var miles=uiSelect[0].dataset.miles;
       if(miles !==''){
           var select=uiSelect.find('select');
           select.val(miles);
       }
    }

    this.dispose = ()=> {
        dom.unbind();
    };

});


   