import elliptical from '../references/elliptical';
import container from '../dependencies/container';
import keys from '../references/keys';

var apiKey=keys.GOOGLE_API_KEY;
var maxResults=50;
var playlistId='UUjcm_VA6vfH-RijUk9hbkcA';
var apiKey='AIzaSyD1xmx8pT2mCt9jqJjr_t_I2PniYhJOC4I';
var youtubeListApiUrl='https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults='+maxResults+'&playlistId='+playlistId+'&key=' + apiKey;
var youtubeVideoApiUrl='https://www.googleapis.com/youtube/v3/videos?part=snippet&key=' + apiKey;
var thumbRoot='https://i.ytimg.com/vi/';
var youtubeRootUrl='https://www.youtube.com/watch?v=';

class VideoService{
   static get(params,callback){
        var self=this;
        var url=youtubeListApiUrl;
        $.get(url,function(data){
            var items=data.items.map(function(obj){
                var id=obj.snippet.resourceId.videoId;
                var img;
                if(obj.snippet.thumbnails.maxres) img= obj.snippet.thumbnails.maxres.url;
                else img=obj.snippet.thumbnails.standard.url;
                return {
                    id:id,
                    img:img,
                    title:obj.snippet.title,
                    link:youtubeRootUrl + id
                };
            });
               
            callback(null,items);
         })
        .fail(function() {
            callback({statusCode:500,message:'Error retrieving videos'},null);       
        });
   }
}

container.registerType('Video', VideoService);


  