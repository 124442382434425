import elliptical from '../references/elliptical';
import container from '../dependencies/container';


elliptical.binding('search-filters', function (node) {
    var Location=container.getType('Location');
    var DomEvent = container.getType('DomEvent');
    var SHOPPING_GALLERY=document.querySelector('shopping-gallery');
    var dom = new DomEvent(node, this);
    dom.event(document,'paper-radio-group-changed', onRadioChanged);
    dom.event(document,this.click,'[filters-reset]', onFiltersReset);

    initRadioGroupState();

    function onRadioChanged(event){
        var queryString=buildQueryStringFromRadioFilters();
        var url=getBaseUrlPath();
        var baseQueryString=getBaseQueryString();
        if(queryString!=='' && baseQueryString !=='') {
            url+='?' + baseQueryString + '&' + queryString;
        }else if(queryString !=='') {
            url+='?' + queryString;
        }
        Location.href=url;
    }

    function onFiltersReset(event){
        resetFilters();
        var url=getBaseUrlPath();
        Location.href=url;
    }

    function buildQueryStringFromRadioFilters(){
        var queryString='';
        var radioGroups=getRadioGroups();
        $.each(radioGroups,function(index,radioGroup){
             var filterId=radioGroup.getAttribute('filter-id');
             var selectedItem = radioGroup.selectedItem;
             var value=selectedItem.getAttribute('name');
             if(value!=='show_all'){
                 var query='c_' + filterId + '=' + encodeURIComponent(value);
                 if(queryString ==='') queryString+=query;
                 else queryString+='&' + query;
             }
        });
        return queryString;
    }

    function resetFilters(){
        var radioGroups=getRadioGroups();
        $.each(radioGroups,function(index,radioGroup){
             radioGroup.selectIndex(0);
        });
    }

    function getBaseUrlPath(){
        return Location.path;
    }

    function getBaseQueryString(){
        var qs='';
        var queryId=SHOPPING_GALLERY.dataset.queryId;
        var queryType=SHOPPING_GALLERY.dataset.queryType;
        if(queryType && queryType !=='') qs+='$queryType=' + encodeURIComponent(queryType);
        if(queryId && queryId !=='') qs+='&$queryId=' + encodeURIComponent(queryId);
        return qs;
    }

    function getRadioGroups(){
         return dom.find('paper-radio-group');
    }

    function initRadioGroupState(){
         var query=Location.query;
         var radioGroups=getRadioGroups();
         $.each(radioGroups,function(index,radioGroup){
             var filterId=radioGroup.getAttribute('filter-id');
             var queryId='c_' + filterId;
             var value=query[queryId];
             if(value) {
                 value=decodeURIComponent(value);
                 radioGroup.selected=value;
             }
        });
    }

    this.dispose = ()=> {
        dom.unbind();
    };

});


   