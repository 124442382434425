import elliptical from '../references/elliptical';
import container from '../dependencies/container';


elliptical.binding('instagram', function (node) {
    var Instagram=container.getType('Instagram');
    var DomEvent = container.getType('DomEvent');
    var dom = new DomEvent(node, this);
    

    function init(){
        console.log('init');
        getImages();
    }

    async function getImages(){
        console.log('get images');
        try{
            console.log('try');
            var images=await Instagram.getAsync();
        }catch(err){
            console.log(err);
        }
    }
    
    
    init();

    this.dispose = ()=> {
        dom.unbind();
    };

});


   