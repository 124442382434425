
import container from '../dependencies/container';

var $Rest=container.getType('$Rest');
var client = new $Rest({
    protocol: 'https',
    path: '/maps/api/geocode/json',
    port: 443,
    host: 'maps.googleapis.com'
});

class GeoProvider{
   static get(params,resource,query,callback){
       client.get(params,null,null,function(err,data){
           if(!err){
               let result=data.results[0].geometry.location
               callback(null,result)
           }else callback(err,null);
       });
   }
}

container.registerType('$GeoProvider', GeoProvider);


  