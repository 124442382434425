
import elliptical from '../references/elliptical';
import container from './container';

var PageCategoryService=elliptical.PageCategoryService;
var PageService=elliptical.PageService;
var PageTemplate=elliptical.PageTemplate;
var FileService=elliptical.FileService;
var $Rest=container.getType('$Rest');
var $rest=new $Rest();

if(PageService){
  //init Page Templates
  var template=new elliptical.PageTemplate();
  template.init();
  var obj={
        id:'article-video',
        name:'Two column article with video',
        description:null,
        image:'/images/page/templates/article-video.svg',
        cssClassOptions:['default','reverse'],
        placeholder:{
          title:'Content header',
          body:'Content goes here',
          video:null
        }
  };

  //add video template
  template.add(obj);

  container.mapType('Page', PageService, new elliptical.PageRestProvider($rest));
  container.mapType('PageCategory', PageCategoryService, new elliptical.PageCategoryRestProvider($rest));
  container.mapType('File', FileService, new elliptical.FileRestProvider($rest));
  container.registerType('PageTemplate', template);
}


export default container;