import elliptical from '../references/elliptical';
import container from '../dependencies/container';
import utils from '../references/utils';

elliptical.binding('send-friend', function (node) {
    var random=utils.random;
    var id=random.id(6);
    var URL=node.dataset.url;
    var ITEM=node.dataset.item;
    var SKU=node.dataset.sku;
    var TEMPLATE_NAME='shared.send-friend';
    var ACTION_ID='send-friend-' + id;
    var dialog=document.querySelector('#pageDialog');
    var template=container.getType('template');
    var Notify= container.getType('Notify');

    var DomEvent = container.getType('DomEvent');
    var dom = new DomEvent(node, this);
    dom.event(this.click,onClick);
    dom.event(document,'md-dialog-action', onSubmit);
    dom.event(document,'silicon-form-success', onSuccess);

    function onClick(event){
       var model={
          item:ITEM,
          url:URL,
          sku:SKU
       };
       dialog.setAction('Submit',ACTION_ID);
       template.render(TEMPLATE_NAME,{model:model},function(err,out){
          dialog.setContent(out);
          dialog.open(true,500,500);
          var form=dialog.querySelector('silicon-form');
          form.formId=ACTION_ID;
       }); 
    }

    function onSubmit(event){
        if(event.detail.actionId!==ACTION_ID) return;
        var form=dialog.querySelector('silicon-form');
        form.submit()
    }

    function onSuccess(event){
        if(event.detail.id!==ACTION_ID) return;
        dialog.close();
    }

    this.dispose = ()=> {
        dom.unbind();
    };

});


   