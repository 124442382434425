
import HomeController from './controllers/homeController';
import PageController from './controllers/pageController';
import ProductController from './controllers/productController';
import PromotionController from './controllers/promotionController';
import CampaignController from './controllers/campaignController';
import CustomController from './controllers/customController';
import container from './dependencies/page';

export default (app)=>{
//-------controllers------------------------------------------------------------
    var PageService=container.getType('Page');
    new HomeController(app,'Home','/@action');
    new PageController(app,'Page',PageService,'shared.page.');
    new ProductController(app,'Product','/@action/:id');
    new PromotionController(app,'Promotion','/@action');
    new CustomController(app,'Custom','/@action/:id');
    new CampaignController(app,'Campaign','/@action/:id');
    
}